<template>
<div style="padding-bottom: 30px; ">
    <!-- <v-row style="margin-top: 0px" v-show="!$vuetify.breakpoint.mobile"></v-row> -->
    <waiting-modal :title="title" :status="status" :active="active_waiting" @statusdailog="update_status_wait"></waiting-modal>
    <!-- {{district_list}}
    {{filterSubdistrict}} -->
    <v-dialog v-model="dialog" width="500">
        <v-card>
            <v-card-text>
                <v-icon x-large color="red darken-2">
                    block
                </v-icon>
                <span>หมายเลข อสม. ไม่ถูกต้อง โปรด<a href="https://www.thaiphc.net/phc/phcadmin/administrator/Program/OSMPR00001_SEARCH1.php" target="_blank">ตรวจสอบ</a></span>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog = false">
                    ตกลง
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog persistent v-model="addProviderListdialog" width="500">
        <v-card>
            <v-card-title>
                <span style="color:#a84192; font-size:18px;">เพิ่มคลินิก</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation style="width: 100%">
                        <v-text-field v-model="new_provider_list_name" :rules="nameRules" label="ชื่อคลินิก" dense outlined required></v-text-field>
                        <v-text-field counter="5" v-model="postcode" type="tel" :rules="nameRules" label="รหัสไปรษณีย์" dense outlined required></v-text-field>
                        <v-text-field v-model="province" label="จังหวัด" dense outlined disabled></v-text-field>
                        <v-select v-model="selected_district" :item-value="district_list" :items="district_list" :rules="[(v) => !!v || 'โปรดเลือก']" label="อำเภอ/เขต" dense outlined required></v-select>
                        <v-select v-model="location_id" :item-value="'location_id'" :item-text="'subdistrict'" :items="filterSubdistrict" :rules="[(v) => !!v || 'โปรดเลือก']" label="ตำบล/แขวง" dense outlined required></v-select>

                        <!-- {{selected_district}} -->
                        <!-- {{selected_district_list}} -->
                        <!-- {{filterSubdistrict}} -->
                        <!-- {{location_id}} -->
                    </v-form>
                    <br>
                    <v-row class="d-flex justify-space-around">
                        <v-btn color="primary" outlined style="color:#a84192" @click="addProviderListdialog = false">
                            ยกเลิก
                        </v-btn>
                        <v-btn color="#a84192" width="40%" class="text-white" @click="validate_new_provider_list">
                            ตกลง
                        </v-btn>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog fullscreen width="100%" v-model="dialogRequest" persistent style="">
        <!-- <v-dialog width="500" v-model="s" persistent style=""> -->
        <v-card>

            <v-card-text>

                <v-card class="pt-10" style="">


              
                    <v-card-text style="border-radius: 7px; padding-top: 25px;text-align:center;">
                        <v-icon :size="80" x-large color="blue darken-2">
                            info
                        </v-icon>
                        <br>
                        <br>
                        <span style="color:#a84192; ">
                            **หากท่านยังไม่มี <b style="font-size:16px;">Token</b> สำหรับลงทะเบียน โปรดกรอกข้อมูลและทำการ <b style="font-size:16px;">Request Token</b>  <br><br>
                            หากมีแล้วโปรดกดปุ่ม 'ต่อไป' เพื่อเข้าสู่การลงทะเบียน
                        </span>
                        <br>
                        <br>
                        <br>
                        <v-form ref="form_2" v-model="valid" lazy-validation style="width: 100%">
                            <v-select v-model="prefix" value="นาย" class="custom-field" :items="['นาย', 'นาง', 'นางสาว', 'ไม่ระบุ']" :rules="[(v) => !!v || 'โปรดเลือก']" label="คำนำหน้าชื่อ" dense outlined required></v-select>
                            <v-text-field v-model="first_name" :rules="nameRules" type="text" label="ชื่อ" dense outlined required></v-text-field>
                            <v-text-field v-model="last_name" :rules="nameRules" type="text" label="นามสกุล" dense outlined required></v-text-field>
                            <v-text-field v-model="mdnumber" :rules="nameRules" label="เลขที่ใบประกอบวิชาชีพเวชกรรม" dense outlined required></v-text-field>
                        </v-form>
                        
                        <v-row class="d-flex justify-center pt-10">
                            <v-btn @click="validate_token_request"  class="" outlined style="height: 40px;" color="#A84292">
                                <v-icon left>key</v-icon><span style="font-size: 12px;">Request Token</span>
                            </v-btn>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="purple" text @click="$router.go(-1)">
                    ยกเลิก
                </v-btn>
                <v-btn color="#A84192 " class="text-white" @click="dialogRequest = false">
                    ต่อไป
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-row class="align-center d-flex h-0">
        <v-col lg="4" sm="6" class="mx-auto" style="padding: 0px 0px 0px 0px">
            <v-card class="" style="">
                <v-card-title class="text-primary" style="
              justify-content: center;
              font-weight: 600 !important;
              padding-bottom: 20px;
              font-size: 24px;
            ">
                    <span>{{form_title}}</span>
                </v-card-title>

                <v-card-subtitle style="
              height: 23px;
              text-align: center !important;
              background-color: #ac55991f !important;
              color: #a84192;
            ">
                    <span>
                        <li>{{form_sub_title}}</li>
                    </span>
                </v-card-subtitle>

                <v-card-text style="border-radius: 7px; padding-top: 25px">
                    <!-- user_provider -->
                    <v-row v-if="user_type == 'user_provider'" dense align="center">
                        <v-form ref="form_2" v-model="valid" lazy-validation style="width: 100%">
                            <v-select v-model="prefix" value="นาย" class="custom-field" :items="['นาย', 'นาง', 'นางสาว', 'ไม่ระบุ']" :rules="[(v) => !!v || 'โปรดเลือก']" label="คำนำหน้าชื่อ" dense outlined required></v-select>
                            <v-text-field v-model="first_name" :rules="nameRules" type="text" label="ชื่อ" dense outlined required></v-text-field>
                            <v-text-field v-model="last_name" :rules="nameRules" type="text" label="นามสกุล" dense outlined required></v-text-field>
                            <v-text-field v-model="mdnumber" :rules="nameRules" label="เลขที่ใบประกอบวิชาชีพเวชกรรม" dense outlined required></v-text-field>
                            <v-row>
                                <v-col cols="8">
                                    <!-- bk -->
                                    <!-- {{provider_list_id}} -->
                                    <!-- <v-autocomplete @click="checkProviderList" v-model="provider_list_id" :rules="[(v) => !!v || 'โปรดเลือก หรือกด เพิ่มผู้ให้บริการ']" :item-value="'id'" :item-text="'provider_name_location'" :items="provider_list" no-data-text="ไม่พบข้อมูล โปรดกดเพิ่มผู้ให้บริการ" outlined dense label="คลินิกส่วนตัว"></v-autocomplete> -->
                                    <v-autocomplete clearable @click="checkProviderList" v-model="provider_list_id" :item-value="'id'" :item-text="'provider_name_location'" :items="provider_list" no-data-text="ไม่พบข้อมูล ท่านสามารถเพิ่มคลินิกได้ที่ปุ่มด้านขวา" outlined dense required label="คลินิกส่วนตัว" :rules="[(v) => !!v || 'โปรดเลือก']"></v-autocomplete>
                                </v-col>
                                <v-col cols="4">
                                    <v-btn width="100%" @click="checkBeforeAddProviderList" class="" style="height: 40px;" outlined color="#A84292">
                                        <v-icon left>add_home_work</v-icon>
                                        <span style="font-size: 13px;">เพิ่ม</span>

                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-text-field counter="5" disabled v-model="postcode" type="tel" label="รหัสไปรษณีย์" dense outlined></v-text-field>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field v-model="province" label="จังหวัด" dense outlined disabled></v-text-field>
                                </v-col>
                                <!-- <v-col cols="4">
                                    <v-text-field v-model="district" label="อำเภอ/เขต" dense outlined disabled></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field v-model="subdistrict" label="ตำบล/แขวง" dense outlined disabled></v-text-field>
                                </v-col> -->
                            </v-row>
                            <!-- <v-text-field v-model="address" label="เลขที่ อาคาร/หมู่บ้าน ถนน" dense outlined disabled></v-text-field> -->
                            <v-text-field  type="tel" v-model="phone_number" :rules="phoneRules" label="เบอร์โทรศัพท์" dense outlined required></v-text-field>

                            <v-row>
                                <v-col cols="12">
                                    <v-text-field v-model="reg_token" :rules="nameRules" label="Register Token (from Eisai)" dense outlined required style="color: purple"></v-text-field>
                                </v-col>

                            </v-row>
                            <br>
                        </v-form>
                    </v-row>

                    <!-- user_osm -->
                    <v-row v-if="user_type == 'user_osm'" dense align="center">
                        <v-form ref="form_2" v-model="valid" lazy-validation style="width: 100%">
                            <v-select class="custom-field" v-model="prefix" :items="['นาย', 'นาง', 'นางสาว', 'ไม่ระบุ']" :rules="[(v) => !!v || 'โปรดเลือก']" label="คำนำหน้าชื่อ" dense outlined required></v-select>
                            <v-text-field v-model="first_name" :rules="nameRules" type="text" label="ชื่อ" dense outlined required></v-text-field>
                            <v-text-field v-model="last_name" :rules="nameRules" type="text" label="นามสกุล" dense outlined required></v-text-field>
                            <v-text-field v-model="idcard" :rules="nameRules" counter="13" type="tel" label="เลขบัตรประชาชน" dense outlined required></v-text-field>
                            <v-text-field  type="tel" v-model="phone_number" :rules="phoneRules" label="เบอร์โทรศัพท์" dense outlined required></v-text-field>
                            <br>
                        </v-form>
                    </v-row>

                    <!-- user_patient -->
                    <v-row v-if="user_type == 'user_patient'" dense align="center">
                        <v-form ref="form_2" v-model="valid" lazy-validation style="width: 100%">
                            <v-select class="custom-field" v-model="prefix" :items="['นาย', 'นาง', 'นางสาว', 'ไม่ระบุ']" :rules="[(v) => !!v || 'โปรดเลือก']" label="คำนำหน้าชื่อ" dense outlined required></v-select>
                            <v-text-field v-model="first_name" :rules="nameRules" label="ชื่อ" dense outlined required></v-text-field>
                            <v-text-field v-model="last_name" :rules="nameRules" label="นามสกุล" dense outlined required></v-text-field>

                            <v-menu ref="menu" v-model="menu" :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="birthdate" :rules="dateRules" label="วัน/เดือน/ปีเกิด" append-icon="calendar_month" dense outlined required readonly v-bind="attrs" v-on:click:append="menu=true" v-on="on"></v-text-field>

                                </template>
                                <v-date-picker v-model="birthdate" locale="th-th" :active-picker.sync="activePicker" no-title :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)" min="1920-01-01" @change="save"></v-date-picker>
                            </v-menu>
                            <v-text-field v-model="idcard" :rules="nameRules" counter="13" type="tel" label="เลขบัตรประชาชน" dense outlined required></v-text-field>
                            <v-row class="margin-bottom: -21px;">
                                <v-col>
                                    <v-text-field v-model="weight" type="tel" :rules="wRules" label="น้ำหนัก (kg)" dense outlined></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field v-model="height" type="tel" :rules="wRules" label="ส่วนสูง (cm)" dense outlined></v-text-field>
                                </v-col>
                            </v-row>
                            <span>โรคประจำตัว</span>
                            <v-row>
                                <v-col>
                                    <v-checkbox v-model="highpressure" label="ความดันสูง" color="primary" dense hide-details></v-checkbox>
                                    <v-checkbox v-model="hyperlipidemia" label="ไขมันสูง" color="primary" dense hide-details></v-checkbox>
                                    <v-checkbox v-model="alzheimer" label="อัลไซเมอร์" color="primary" dense hide-details></v-checkbox>

                                </v-col>
                                <v-col>
                                    <v-checkbox v-model="diabetes" label="เบาหวาน" color="primary" dense hide-details></v-checkbox>
                                    <v-checkbox v-model="arrhythmai" label="หัวใจเต้นผิดจังหวะ" color="primary" dense hide-details></v-checkbox>
                                    <v-checkbox v-model="depress" label="ซึมเศร้า" color="primary" dense hide-details></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-checkbox v-model="heart" label="โรคหัวใจหรือหลอดเลือดสมอง" color="primary" dense hide-details style="padding-bottom: 21px;"></v-checkbox>

                            <v-text-field counter="5" v-model="postcode" type="tel" :rules="nameRules" label="รหัสไปรษณีย์" dense outlined required></v-text-field>

                            <v-row>
                                <v-col cols="4">
                                    <v-text-field v-model="province" label="จังหวัด" dense outlined disabled></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-select v-model="selected_district" :item-value="district_list" :items="district_list" :rules="[(v) => !!v || 'โปรดเลือก']" label="อำเภอ/เขต" dense outlined required></v-select>
                                </v-col>
                                <v-col cols="4">
                                    <v-select v-model="location_id" :item-value="'location_id'" :item-text="'subdistrict'" :items="filterSubdistrict" :rules="[(v) => !!v || 'โปรดเลือก']" label="ตำบล/แขวง" dense outlined required></v-select>
                                </v-col>
                            </v-row>
                            <v-text-field v-model="address" label="เลขที่ อาคาร/หมู่บ้าน ถนน" dense outlined></v-text-field>
                            <v-text-field  type="tel" v-model="phone_number" :rules="phoneRules" label="เบอร์โทรศัพท์" dense outlined required></v-text-field>
                            <!-- {{selected_district}} -->
                            <!-- {{selected_district_list}} -->
                            <!-- {{filterSubdistrict}} -->
                            <!-- {{location_id}} -->
                            <br>

                        </v-form>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <!-- {{selected_provider}} -->
    <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center" justify="center" style="padding-top: 15px">
        <v-btn @click="goBack" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
            <v-icon small>west</v-icon>
        </v-btn>
        &nbsp; &nbsp; &nbsp; &nbsp;
        <v-btn @click="validate" style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
            ยืนยันข้อมูล
        </v-btn>
    </v-row>
    <!-- {{selected_provider_list}} -->

    <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
        <v-row class="align-center" justify="center">
            <v-btn @click="goBack" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                <v-icon small>west</v-icon>
            </v-btn>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <v-btn @click="validate" style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
                ยืนยันข้อมูล
            </v-btn>
        </v-row>
    </v-footer>
</div>
</template>

<script>
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
import WaitingModal from "@/components/WaitingModal.vue";
export default {
    name: "register-select-type",
    components: {
        WaitingModal,
    },
    data() {
        return {
            dialogRequest:false,
            selected_provider: '',
            new_provider_list_name: '',
            addProviderListdialog: false,
            register_form: {},
            filltered_subdistrict_list: [],
            selected_district: '',
            selected_district_list: [],
            provider_list: [],
            provider_list_id: '',
            dialog: false,
            active_waiting: false,
            title: '',
            status: '',
            postcode: '',
            form_title: '',
            form_sub_title: '',
            activePicker: null,
            date: null,
            menu: false,
            user_type: "",
            pdpa_accept: false,
            valid: true,
            name: "",
            prefix: '',
            first_name: '',
            last_name: '',
            birthdate: '',
            phone_number: '',
            idcard: '',
            mdnumber: '',
            reg_token: '',
            passcode: '',
            lineid: '',
            weight: '',
            height: '',
            highpressure: 0,
            hyperlipidemia: 0,
            alzheimer: 0,
            heart: 0,
            diabetes: 0,
            arrhythmai: 0,
            depress: 0,
            province: '',
            district: '',
            subdistrict: '',
            subdistrict_list: [],
            location_id: '',
            location_list: '',
            district_list: [],
            provider_list_type: 'clinic',
            address: '',
            nameRules: [
                v => !!v || "โปรดกรอกข้อมูล",

            ],
            phoneRules: [
                v => (v && v.length > 8 && v.length < 11) || "โปรดกรอกเลขหมายที่ถูกต้อง"
            ],
            wRules: [
                v => (v && v > 30 && v < 220) || "โปรดกรอกจำนวนที่ถูกต้อง"
            ],
            dateRules: [v => !!v || "โปรดเลือกวันที่", ],

        };
    },

    mounted() {
        //console.log(this.$store.state.register_form)
        this.user_type = this.$store.state.register_form.user_type
        this.pdpa_accept = this.$store.state.register_form.pdpa_accept
        this.prefix = this.$store.state.register_form.prefix

        if (this.$store.state.register_form.first_name) {
            this.first_name = this.$store.state.register_form.first_name
        }
        if (this.$store.state.register_form.last_name) {
            this.last_name = this.$store.state.register_form.last_name
        }

        this.birthdate = this.$store.state.register_form.birthdate
        this.phone_number = this.$store.state.register_form.phone_number
        this.idcard = this.$store.state.register_form.idcard
        if (this.$store.state.register_form.mdnumber) {
            this.mdnumber = this.$store.state.register_form.mdnumber
        }
        // this.provider_list_id = this.$store.state.register_form.provider_list_id
        if (this.$store.state.register_form.reg_token) {
            this.reg_token = this.$store.state.register_form.reg_token
        }
        if (this.$store.state.register_form.passcode) {
            this.passcode = this.$store.state.register_form.passcode
        }
        this.lineid = this.$store.state.register_form.lineid
        this.weight = this.$store.state.register_form.weight
        this.height = this.$store.state.register_form.height
        this.highpressure = this.$store.state.register_form.highpressure
        this.hyperlipidemia = this.$store.state.register_form.hyperlipidemia
        this.alzheimer = this.$store.state.register_form.alzheimer
        this.heart = this.$store.state.register_form.heart
        this.diabetes = this.$store.state.register_form.diabetes
        this.arrhythmai = this.$store.state.register_form.arrhythmai
        this.depress = this.$store.state.register_form.depress
        this.province = this.$store.state.register_form.province
        this.district = this.$store.state.register_form.district
        this.subdistrict = this.$store.state.register_form.subdistrict
        if (this.$store.state.register_form.address) {
            this.address = this.$store.state.register_form.address
        }
        this.postcode = this.$store.state.register_form.postcode
        this.location_id = this.$store.state.register_form.location_id
        this.getFromTitle()
        if (this.user_type === 'user_provider') {
            // setTimeout(() => {
            //     this.active_waiting = true
            //     this.title = 'ระบบลงทะเบียนต้องใช้ <span style="color:purple"> Register Token </span> <br> หากท่านยังไม่มี สามารถขอได้จากปุ่ม <span style="color:purple"> REQUEST </span> <br></span>ด้านล่าง'
            //     this.status = 'info'
            // }, 500)
            setTimeout(() => {
                this.dialogRequest = true
            }, 0)
            

        }

    },

    watch: {
        provider_list_id(val) {
            if (val) {
                const seleted_provider = this.provider_list.filter(item => item.id == val)[0]
                //console.log(seleted_provider)
                if (seleted_provider.postcode) {
                    this.postcode = seleted_provider.postcode
                }
                this.subdistrict = seleted_provider.subdistrict
                this.address = seleted_provider.address
                //console.log('88888')
                // //console.log(seleted_provider)
                this.selected_provider = seleted_provider
                //console.log(this.selected_provider)
            }
            // if(val == ''){
            //     this.subdistrict = ''
            //     this.seleted_provider.postcode = ''
            // }
            if (!val) {
                //console.log('this.provider_list_id--->', this.provider_list_id)
                this.postcode = ''
                this.province = ''
            }

            // this.subdistrict = seleted_provider.subdistrict
        },
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
        async postcode(val) {
            if (val && val.length == 5) {
                this.active_waiting = true
                this.title = 'กำลังค้นหา',
                    this.status = 'loading'
                await Vue.axios.post(APIURL + '/get_location', {
                    "postcode": val
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data) {
                        this.active_waiting = false
                        //console.log(response.data)
                        this.province = response.data.province
                        this.district = response.data.district
                        this.subdistrict_list = response.data.subdistrict_list
                        this.location_id = response.data.location_id
                        this.location_list = response.data.location_list
                        //console.log('*******************')
                        //console.log(this.location_id)
                        this.getDistrictKeys()
                    } else {
                        this.active_waiting = false
                        this.province = 'ไม่พบข้อมูล'
                        this.district = 'ไม่พบข้อมูล'
                    }

                })
            }

        }
    },
    computed: {
        filterSubdistrict: function () {
            // //console.log(this.postcode)
            if (!this.postcode || this.postcode.length < 5 || !this.filterSubdistrictByDistrict(this.selected_district)) {
                return []
            }
            return this.filterSubdistrictByDistrict(this.selected_district)
        },

    },
    methods: {
        checkBeforeAddProviderList() {
            //console.log(this.first_name.length)
            if (this.first_name.length == 0 || this.last_name.length == 0 || this.first_name.mdnumber == 0) {
                this.active_waiting = true
                this.title = 'โปรดกรอกข้อมูล <br> ชื่อ-นามสกุล และเลขที่ใบประกอบวิชาชีพเวชกรรม <br> เพื่อเพิ่มคลินิกส่วนตัว'
                this.status = 'info'
            } else {

                this.checkMDbeforAddProviderList()
            }
        },
        async submitAddProviderList() {

            this.dialog_new = false
            this.active_waiting = true
            this.title = 'กำลังเพิ่มข้อมูล',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/add_provider_list', {
                "provider_name": this.new_provider_list_name,
                "location_id": this.location_id,
                "address": "",
                "type": this.provider_list_type,
                "is_active": 0

            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data) {
                    this.title = 'เพิ่มข้อมูลสำเร็จ';
                    this.status = 'done'
                    setTimeout(() => {
                        this.active_waiting = false
                    }, 2000)
                    // this.get_alert_record()
                    // this.$refs.form.reset()
                    this.addProviderListdialog = false
                    this.postcode = ''
                    this.province = ''
                    // this.$router.push('/book_doctor_list')
                } else {
                    this.active_waiting = false
                    this.title = 'ระบบขัดข้อง',
                        this.status = 'fail'
                }

            })

        },
        checkProviderList() {
            //console.log(this.provider_list)
            //console.log(this.provider_list.length)

            if (this.provider_list) {
                //console.log('getProviderList')
                this.getProviderList()
            }
        },
        async getProviderList() {
            this.active_waiting = true
            this.title = '',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/get_provider_list', {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data) {
                    this.active_waiting = false
                    //console.log(response.data)
                    this.provider_list = response.data.provider_list;
                    this.provider_list = this.provider_list.filter(provider => !provider.type.indexOf("clinic"))
                    //console.log('777777777777777')
                    //console.log(this.provider_list)

                } else {
                    this.active_waiting = false
                    //console.log(response)
                }

            })

        },
        getDistrictKeys() {
            const temp_list = this.location_list.reduce((prev, cur) => ({
                ...prev,
                [cur.district]: (prev[cur.district] || []).concat(cur)
            }), {})
            const keys = Object.keys(temp_list);
            // print all keys
            //console.log(keys);
            this.district_list = keys
        },

        filterProductsByName: function () {
            return this.products.filter(product => !product.name.indexOf(this.name))
        },

        filterSubdistrictByDistrict: function (subdistrict_list) {
            //console.log(subdistrict_list)
            return this.selected_district ?
                this.location_list.filter((location_list) => !location_list.district.indexOf(this.selected_district)) :
                this.location_list;

        },
        update_status_wait(val) {
            this.active_waiting = val
        },

        getFromTitle() {
            if (this.user_type === 'user_provider') {
                this.form_title = 'ลงทะเบียนโรงพยาบาล/คลินิก'
                this.form_sub_title = 'Healthcare provider'
            }
            if (this.user_type === 'user_osm') {
                this.form_title = 'ลงทะเบียน อสม.'
                this.form_sub_title = 'Healthcare provider'
            }
            if (this.user_type === 'user_patient') {
                this.form_title = 'ลงทะเบียนผู้ใช้งาน/ผู้ป่วย'
                this.form_sub_title = 'Caregiver/Patient'
            }
        },
        save(date) {
            this.$refs.menu.save(date)
        },
        validate() {
            const res = this.$refs.form_2.validate();
            //console.log(res)
            if (res) {
                this.submitRegister()
            }
            // 
        },

        validate_new_provider_list() {
            const res = this.$refs.form.validate();
            //console.log(res)
            if (res) {
                this.submitAddProviderList()
            }
            // 
        },

        validate_token_request() {
            const res = this.$refs.form_2.validate();
            this.reg_token = ' '
            //console.log(res)
            if (res) {
                this.checkMDbeforRequestToken()
            }
            // 
        },
        async submitRequestToken() {
            //call api check provider
            this.active_waiting = true
            this.title = 'กำลังส่งคำขอ Token สำหรับลงทะเบียน',
                this.status = 'loading'

            //console.log(this.lineid)
            //console.log(this.prefix)
            //console.log(this.first_name)
            //console.log(this.last_name)
            //console.log(this.phone_number)
            //console.log(this.mdnumber)
            //console.log(this.provider_list_id)
            //console.log(this.selected_provider.provider_name_location)

            await Vue.axios.post(APIURL + '/add_request_token', {
                "lineid": this.lineid,
                "prefix": this.prefix,
                "first_name": this.first_name,
                "last_name": this.last_name,
                "phone_number": '',
                "mdnumber": this.mdnumber,
                "provider_list_id": 0,
                "provider_name_location": '',
                "status": 'new'
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                //console.log('77777777777777777777')
                //console.log(response)
                this.res = response.data
                if (this.res.detail == "add_request_token_done") {
                    //console.log('77777777this.res.detail777777777777')
                    //console.log(this.res.detail)
                    this.status = 'success'
                    this.title = 'ส่งคำขอของท่านแล้ว<br>ท่านสามารถปิดหน้าต่างนี้และกลับสู่หน้าหลัก<br>\
                    เพื่อรอแอดมินตรวจสอบและติดต่อกลับ';

                    // this.$store.commit('setRegisterForm', this.register_form);

                    // setTimeout(() => {
                    //     this.active_waiting = false;
                    //     // this.submitRequestToken();
                    // }, 2000)
                } else {
                    this.title = 'ระบบขัดข้อง';
                    this.status = 'fail'
                }

            });

        },
        // async submitRequestToken() {
        //     //call api check provider
        //     this.active_waiting = true
        //     this.title = 'กำลังส่งคำขอ Token สำหรับลงทะเบียน',
        //         this.status = 'loading'

        //     //console.log(this.lineid)
        //     //console.log(this.prefix)
        //     //console.log(this.first_name)
        //     //console.log(this.last_name)
        //     //console.log(this.phone_number)
        //     //console.log(this.mdnumber)
        //     //console.log(this.provider_list_id)
        //     //console.log(this.selected_provider.provider_name_location)

        //     await Vue.axios.post(APIURL + '/add_request_token', {
        //         "lineid": this.lineid,
        //         "prefix": this.prefix,
        //         "first_name": this.first_name,
        //         "last_name": this.last_name,
        //         "phone_number": this.phone_number,
        //         "mdnumber": this.mdnumber,
        //         "provider_list_id": this.provider_list_id,
        //         "provider_name_location": this.selected_provider.provider_name_location,
        //         "status": 'new'
        //     }, {
        //         headers: {
        //             'Content-Type': 'application/json'
        //         }
        //     }).then((response) => {
        //         //console.log('77777777777777777777')
        //         //console.log(response)
        //         this.res = response.data
        //         if (this.res.detail == "add_request_token_done") {
        //             //console.log('77777777this.res.detail777777777777')
        //             //console.log(this.res.detail)
        //             this.status = 'success'
        //             this.title = 'ส่งคำขอของท่านแล้ว<br>โปรดรอแอดมินตรวจสอบและติดต่อกลับ';

        //             // this.$store.commit('setRegisterForm', this.register_form);

        //             // setTimeout(() => {
        //             //     this.active_waiting = false;
        //             //     // this.submitRequestToken();
        //             // }, 2000)
        //         } else {
        //             this.title = 'ระบบขัดข้อง';
        //             this.status = 'fail'
        //         }

        //     });

        // },
        async checkMDbeforAddProviderList() {
            // send submit_form to the server
            // await      
            //call api check provider
            //console.log()
            this.active_waiting = true
            this.title = 'กำลังตรวจสอบใบประกอบวิชาชีพ',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/check_user_provider', {
                "mdnumber": this.mdnumber,
                "doc_name": this.first_name,
                "doc_lastname": this.last_name,

            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                //console.log('77777777777777777777')
                //console.log(response)
                this.res = response.data
                if (this.res.detail == "mdnumber_is_valid") {
                    //console.log('77777777this.res.detail777777777777')
                    //console.log(this.res.detail)
                    this.status = 'done'
                    this.title = 'เลขใบประกอบวิชาชีพถูกต้อง';

                    this.$store.commit('setRegisterForm', this.register_form);

                    setTimeout(() => {
                        this.active_waiting = false;
                        // this.submitRequestToken();
                        this.addProviderListdialog = true
                    }, 2000)

                } else if (this.res.detail == 'mdnumber_already_registered') {
                    this.title = 'เลข MD นี้ลงทะเบียนไปแล้ว';
                    this.status = 'fail'
                } else if (this.res.detail == 'mdnumber_is_not_valid') {
                    this.title = 'เลข MD นี้ไม่ถูกต้อง';
                    this.status = 'fail'
                } else if (this.res.detail == 'doctor_is_not_valid') {
                    this.title = 'ไม่พบ ชื่อ-สกุล ในฐานข้อมูล';
                    this.status = 'fail'
                } else {
                    this.title = 'ระบบขัดข้อง';
                    this.status = 'fail'
                }

            });
        },

        async checkMDbeforRequestToken() {
            // send submit_form to the server
            // await      
            //call api check provider
            //console.log()
            this.active_waiting = true
            this.title = 'กำลังตรวจสอบใบประกอบวิชาชีพ',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/check_user_provider', {
                "mdnumber": this.mdnumber,
                "doc_name": this.first_name,
                "doc_lastname": this.last_name,

            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                //console.log('77777777777777777777')
                //console.log(response)
                this.res = response.data
                if (this.res.detail == "mdnumber_is_valid") {
                    //console.log('77777777this.res.detail777777777777')
                    //console.log(this.res.detail)
                    this.status = 'done'
                    this.title = 'เลขใบประกอบวิชาชีพถูกต้อง';

                    this.$store.commit('setRegisterForm', this.register_form);

                    setTimeout(() => {
                        this.active_waiting = false;
                        this.submitRequestToken();
                    }, 2000)

                } else if (this.res.detail == 'mdnumber_already_registered') {
                    this.title = 'เลข MD นี้ลงทะเบียนไปแล้ว';
                    this.status = 'fail'
                } else if (this.res.detail == 'mdnumber_is_not_valid') {
                    this.title = 'เลข MD นี้ไม่ถูกต้อง';
                    this.status = 'fail'
                } else if (this.res.detail == 'doctor_is_not_valid') {
                    this.title = 'ไม่พบ ชื่อ-สกุล ในฐานข้อมูล';
                    this.status = 'fail'
                } else {
                    this.title = 'ระบบขัดข้อง';
                    this.status = 'fail'
                }

            });
        },

        async submitRegister() {
            // send submit_form to the server
            // await        
            //console.log('------------------->', this.provider_list_id)
            if (this.provider_list_id == 0) {
                this.provider_list_id = 1
            }

            this.register_form = {
                "user_type": this.user_type,
                "user_id": '',
                "location_id": this.location_id,
                "address": this.address,
                "pdpa_accept": this.pdpa_accept,
                "gender": "m",
                "birthdate": this.birthdate,
                "prefix": this.prefix,
                "first_name": this.first_name,
                "last_name": this.last_name,
                "phone_number": this.phone_number,
                "idcard": this.idcard,
                "lineid": this.lineid,
                "weight": this.weight,
                "height": this.height,
                "highpressure": this.highpressure,
                "hyperlipidemia": this.hyperlipidemia,
                "alzheimer": this.alzheimer,
                "heart": this.heart,
                "diabetes": this.diabetes,
                "arrhythmai": this.arrhythmai,
                "depress": this.depress,
                "mdnumber": this.mdnumber,
                "provider_list_id": this.provider_list_id,
                "reg_token": this.reg_token,
                "passcode": this.passcode,
                "postcode": this.postcode
            }

            if (this.user_type == 'user_patient') {
                //console.log(this.register_form)
                //call api patient
                this.active_waiting = true
                this.title = 'กำลังลงทะเบียน',
                    this.status = 'loading'

                await Vue.axios.post(APIURL + '/register_user_patient', this.register_form, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    this.res = response.data
                    //console.log(response.data)

                    if (this.res.detail.user_id) {
                        this.title = 'เพิ่มข้อมูลสำเร็จ';
                        this.status = 'done'

                        this.$store.commit('setLoginDetail', {
                            user_id: this.res.detail.user_id,
                            user_type: this.res.detail.user_type,
                            user_token: this.res.detail.user_token,
                        });
                        setTimeout(() => {
                            this.active_waiting = false
                            // this.$router.push('/home_checkmong')
                            this.$router.push('/login')
                        }, 3000)
                    } else {

                        if (this.res.detail == 'lineid_already_registered') {
                            this.title = 'บัญชีไลน์นี้ลงทะเบียนไปแล้ว';
                            this.status = 'fail'
                            this.$store.commit('setLoginDetail', {
                                user_id: this.res.detail.user_id,
                                user_type: this.res.detail.user_type,
                                user_token: this.res.detail.user_token,
                            });
                            setTimeout(() => {
                                localStorage.clear();
                                this.$router.push('/home')
                            }, 3000)

                        }
                        if (this.res.detail == 'idcard_is_not_valid') {
                            this.title = 'เลขบัตรประชาชนไม่ถูกต้อง';
                            this.status = 'fail'
                        }
                        if (this.res.detail == 'idcard_already_registered') {
                            this.title = 'เลขบัตรประชาชนนี้ลงทะเบียนไปแล้ว';
                            this.status = 'fail'
                        }
                        if (this.res.detail == 'cannot_get_last_user_id' || this.res.detail == 'cannot_create_user') {
                            this.title = 'ขออภัย ระบบขัดข้อง';
                            this.status = 'fail'
                        }

                    }
                })
            }
            if (this.user_type == 'user_provider') {
                //call api check provider
                this.active_waiting = true
                this.title = 'กำลังตรวจสอบใบประกอบวิชาชีพ',
                    this.status = 'loading'

                await Vue.axios.post(APIURL + '/check_user_provider', {
                    "mdnumber": this.register_form.mdnumber,
                    "doc_name": this.register_form.first_name,
                    "doc_lastname": this.register_form.last_name,

                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    //console.log('77777777777777777777')
                    //console.log(response)
                    this.res = response.data
                    if (this.res.detail == "mdnumber_is_valid") {
                        //console.log('77777777this.res.detail777777777777')
                        //console.log(this.res.detail)
                        this.status = 'done'
                        this.title = 'เลขใบประกอบวิชาชีพถูกต้อง';

                        this.$store.commit('setRegisterForm', this.register_form);

                        setTimeout(() => {
                            this.active_waiting = false;
                            this.checktoken();
                        }, 2000)

                    } else if (this.res.detail == 'mdnumber_already_registered') {
                        this.title = 'เลข MD นี้ลงทะเบียนไปแล้ว';
                        this.status = 'fail'
                    } else if (this.res.detail == 'mdnumber_is_not_valid') {
                        this.title = 'เลข MD นี้ไม่ถูกต้อง';
                        this.status = 'fail'
                    } else if (this.res.detail == 'doctor_is_not_valid') {
                        this.title = 'ไม่พบ ชื่อ-สกุล ในฐานข้อมูล';
                        this.status = 'fail'
                    } else {
                        this.title = 'ระบบขัดข้อง';
                        this.status = 'fail'
                    }

                });

            }

            // ///////////////////////////
            if (this.user_type == 'user_osm') {

                //call api check osm
                this.active_waiting = true
                this.title = 'กำลังตรวจสอบเลขบัตร อสม.',
                    this.status = 'loading'

                await Vue.axios.post(APIURL + '/check_user_osm', {
                    "idcard": this.register_form.idcard
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    //console.log(response.data)
                    this.res = response.data
                    if (this.res.detail == 'osmid_is_valid') {

                        this.status = 'done'
                        this.title = 'เลขบัตรถูกต้อง';

                        this.$store.commit('setRegisterForm', this.register_form);

                        setTimeout(() => {
                            this.active_waiting = false
                            this.$router.push('/register_passcode')
                        }, 3000)

                    } else if (this.res.detail == 'osmid_is_not_valid') {
                        this.active_waiting = false
                        this.dialog = true
                        this.$store.commit('setRegisterForm', this.register_form);
                        //popup user is not valid please go to check in osm link
                    } else if (this.res.detail == 'idcard_is_not_valid') {
                        this.title = 'เลขบัตรประชาชนไม่ถูกต้อง';
                        this.status = 'fail'
                    } else if (this.res.detail == 'idcard_already_registered') {
                        this.title = 'เลขบัตรนี้ลงทะเบียนไปแล้ว';
                        this.status = 'fail'
                    } else {
                        this.title = 'ระบบขัดข้อง กรุณาลองใหม่อีกครั้ง';
                        this.status = 'fail'
                    }
                });
                // ////////////////////////////
            }

        },
        async checktoken() {
            //console.log('checktoken(){')
            this.active_waiting = true
            this.title = 'กำลังตรวจสอบ Token',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/check_register_token', {
                "reg_token": this.register_form.reg_token,
                "mdnumber": this.register_form.mdnumber
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                //console.log(response.data)
                this.res = response.data
                if (this.res.detail == 'valid_token') {
                    this.status = 'done'
                    this.title = 'Token ถูกต้อง';
                    this.$store.commit('setRegisterForm', this.register_form);

                    setTimeout(() => {
                        this.active_waiting = false
                        this.$router.push('/register_passcode')
                    }, 3000)

                } else if (this.res.detail == 'not_valid_token') {
                    this.title = 'Token ไม่ถูกต้องหรือถูกใช้งานไปแล้ว'
                    this.status = 'fail'

                    //popup user is not valid please go to check in osm link
                }

            });
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        goHomeCheckmong() {

            this.$router.push('/home_checkmong')

        },
        goBack() {
            this.$router.push({
                path: '/pdpa',
                replace: true
            })
        }
    },
};
</script>

<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.action-nav {
    background: #ffffff !important;
    box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    height: 77 px !important;
}

.v-menu__content .v-list-item__title {
    font-size: 0.875rem;
    color: #ffffff;
    color: gray;
}

.custom-field {
    border-radius: 6px;
}

.v-picker__title {
    color: #FFFFFF;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    padding: 16px;
    background-color: #A84192;
}

.v-main__wrap {

    padding-bottom: 90px;
}

.v-select .vs__selected-options {
    flex-wrap: nowrap;
}

.v-btn__content {
    /*
    display:flex;
    flex-direction:column;*/
    font-size: 14px;
}
</style>
